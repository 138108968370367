import React from 'react';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';

const NavigationBar: React.FC = () => {
  return (
    <AppBar position="fixed">
      <Toolbar>
        <Typography
          variant="h6"
          sx={{ flexGrow: 1, textDecoration: 'none', color: 'inherit' }}
          component={Link}
          to="/"
        >
          VetJ
        </Typography>
        <Button color="inherit" component={Link} to="/sign-in">
          Sign In
        </Button>
        <Button color="inherit" component={Link} to="/create-account">
          Create Account
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default NavigationBar;
