import React from 'react';
import './App.css';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import NavigationBar from './components/NavigationBar';
import DemoPage from './pages/DemoPage';
import SignIn from './pages/SignIn';
import CreateAccount from './pages/CreateAccount';

const App: React.FC = () => {
  return (
    <Router>
      <NavigationBar />
      <Routes>
        <Route path="/" element={<DemoPage />} />
        <Route path="/sign-in" element={<SignIn />} />
        <Route path="/create-account" element={<CreateAccount />} />
      </Routes>
    </Router>
  );
};

export default App;
